import React, { useEffect, useState } from "react";
import "./Home.css";
import { Button, Collapse, Skeleton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Vector from "../../../../ClientApp/src/assets/images/Vector.png";
import VectorMobile from "../../../../ClientApp/src/assets/images/VectorMobile.png";
import bot from "../../../../ClientApp/src/assets/images/Bot.png";
import { compose } from "redux";
import { connect } from "react-redux";
import { ApplicationState } from "state";
import {
  loadAllInsightRequests,
  loadEnterpriseClient,
  resetInsightRequestsState,
} from "state/insightRequest/actions";
import { useSelector } from "react-redux";
import { Request } from "abstractions/request/Request";
import { useHistory } from "react-router";
import { LocalizationState } from "state/localization";
import RecentlyCreatedProjects from "components/ReUsableInsightsComponents/RecentlyCreated";
import { ActionTypes } from "state/enums/ActionTypes";
import { makeLoadingSelector } from "state/loading/selectors";

interface InsightTypes {
  Attachments: [unknown];
  CreationTime: string;
  CreatorUser: unknown;
  CreatorUserId: string;
  Description: string;
  EndDate: string;
  Id: string;
  Name: string;
  StartDate: string;
  State: string;
  Status: string;
  Tags: unknown;
  TypeId: string;
  LastModificationTime?: string;
}

interface types {
  localization?: LocalizationState | undefined;
  loadAllInsightRequests?: (
    arg0: number,
    arg1: number,
    arg2: undefined,
    arg3: string[],
    arg4: undefined,
    arg5: boolean,
    arg6: boolean
  ) => unknown;
  resetInsightRequestsState?: () => unknown;
  InsightTypes?: {
    Attachments: [unknown];
    CreationTime: string;
    CreatorUser: unknown;
    CreatorUserId: string;
    Description: string;
    EndDate: string;
    Id: string;
    Name: string;
    StartDate: string;
    State: string;
    Status: string;
    Tags: unknown;
    TypeId: string;
  };
  isEnterPriseClient?: boolean;
  showDashboard?: number;
}

const Home = (props: types) => {
  const loading = useSelector((state: ApplicationState) =>
    makeLoadingSelector([ActionTypes.InsightRequestsRequest])(state)
  );
  const insights: Record<string, Request> | boolean = useSelector(
    (state: ApplicationState) =>
      state?.insightRequest?.requests ? state?.insightRequest?.requests : {}
  );

  const history = useHistory();

  const [showContent, setContent] = useState(true);

  const topFiveRequest = (Object.values(insights) ||
    []) as unknown as InsightTypes[];

  useEffect(() => {
    if (props?.loadAllInsightRequests) {
      void props?.loadAllInsightRequests(
        0,
        5,
        undefined,
        ["LastModificationTime desc"],
        undefined,
        true,
        true
      );
    }

    return () => {
      if (props?.resetInsightRequestsState) {
        void props?.resetInsightRequestsState();
      }
    };
  }, []);

  const content = () => {
    return showContent ? (
      <div id="homeContent" style={{ width: 555 }}>
        <div className="bulletContainer">
          <div className="count">1</div>
          <p className="staticContent">
            {props?.localization?.HomePageGuide1
              ? props?.localization?.HomePageGuide1
              : "Upload preferred content."}
          </p>
        </div>
        <div className="bulletContainer">
          <div className="count">2</div>
          <p className="staticContent">
            {props?.localization?.HomePageGuide2
              ? props?.localization?.HomePageGuide2
              : "Provide a description of your content."}
          </p>
        </div>
        <div className="bulletContainer">
          <div className="count">3</div>
          <p className="staticContent">
            {props?.localization?.HomePageGuide3
              ? props?.localization?.HomePageGuide3
              : "Receive insights within 48 hours."}
          </p>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const getHeadding = () => {
    if (props?.localization?.HomePageHeader1 && topFiveRequest.length > 0) {
      return props?.localization?.HomePageHeader2;
    } else if (
      props?.localization?.HomePageHeader1 &&
      (topFiveRequest.length === 0 || topFiveRequest === undefined)
    ) {
      return props?.localization?.HomePageHeader1;
    } else {
      return "Welcome to XStereotype!";
    }
  };

  const getLatestInsights = () => {
    let data: InsightTypes[] = [];
    topFiveRequest.forEach((ele) => {
      const lastModificationTime = ele?.LastModificationTime;

      if (lastModificationTime !== undefined) {
        const creationTime = new Date(lastModificationTime);

        if (!isNaN(creationTime.getTime())) {
          const hoursDifference =
            (new Date().getTime() - creationTime.getTime()) / 3600000;
          if (hoursDifference <= 48) {
            data.push(ele);
          }
        }
      }
    });

    if (data.length > 3) {
      data = data.slice(0, 3);
    }

    return data;
  };

  if (props.showDashboard === undefined) {
    return (
      <div className="homeContainer" id="homeContainer">
        <img className="vectorImg" src={Vector} />
        <div className="homeContent">
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
          </Stack>
        </div>
      </div>
    );
  }


  return (
    <div className="homeContainer" id="homeContainer">
      <img className="vectorImg" src={Vector} />
      <div className="topBar" style={{ position: "fixed" }}>
        <p className="pTagHome">{""}</p>
        <button
          id="createBtn"
          className="createBtn"
          onClick={() => {
            history?.push(`${process.env.PUBLIC_URL}/create-project`);
          }}
        >
          <AddIcon className="addIcon" />
          <p style={{ margin: 0 }}>Create new project</p>
        </button>
      </div>
      <div className="homeContent">
        <h3 className="xterioHead">{getHeadding()}</h3>
        {loading && (
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
            <Skeleton variant="rounded" width={500} height={40} />
          </Stack>
        )}
        {!loading && (
          <div>
            {topFiveRequest.length > 0 && (
              <>
                <p className="staticContent">
                  Access your brand’s insights reports to better understand how
                  your content resonates across different audiences.
                </p>
                <p
                  id="viewAllInsight"
                  className="viewAll"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/insights`);
                  }}
                >
                  View all
                </p>
                <RecentlyCreatedProjects
                  recentlyCreated={getLatestInsights()}
                  height="auto"
                  width="100%"
                />
              </>
            )}
          </div>
        )}

        {!loading &&
          (topFiveRequest.length === 0 || topFiveRequest === undefined) && (
          <p className="staticContent">
            {props?.localization?.HomePageWelcome1
              ? props?.localization?.HomePageWelcome1
              : "We are dedicated to eliminating stereotypes and bias in marketing. Begin your journey by creating a project and uploading your media assets. Following the assessment, you'll receive a comprehensive insights report, empowering you to make informed decisions about your creative content."}
          </p>
        )}
        <hr className="hrTag" />
        <div className="topFiveHeadder">
          <h3 className="xterioHead">
            {props?.localization?.HomePageGuideHeader
              ? props?.localization?.HomePageGuideHeader
              : "How it works"}
          </h3>
          <p
            className="viewAll"
            id="viewAll"
            onClick={() => {
              setContent(!showContent);
            }}
          >
            {showContent ? "Hide" : "Show"}
          </p>
        </div>
        <Collapse in={showContent}>{content()}</Collapse>
        <button
          className="createBtn"
          id="createBtn1"
          onClick={() => {
            history?.push(`${process.env.PUBLIC_URL}/create-project`);
          }}
        >
          {" "}
          <AddIcon className="addIcon" />{" "}
          <p style={{ margin: 0 }}>Create new project</p>
        </button>
      </div>
      <img className="botImg" id="botImg" src={bot} />
      <div className="mobileImgContainer">
        <img className="VectorMobile" src={VectorMobile} />
        <img className="botImgMobile" src={bot} />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  loadAllInsightRequests,
  resetInsightRequestsState,
  loadEnterpriseClient,
};
const mapStateToProps = (state: ApplicationState) => ({
  loading: makeLoadingSelector([ActionTypes.InsightRequestsRequest])(state),
  localization: state?.localization,
  insightsRequest: state?.insightRequest?.requests
    ? state?.insightRequest?.requests
    : {},
  isEnterPriseClient: state?.insightRequest?.isEnterPriseClient,
  showDashboard: state?.insightRequest?.showDashboard,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
