import { cloneDeep, forEach } from "lodash";
import { Action } from "redux";
import { AppThunkAction } from "..";
import { DisplayTypes } from "../../abstractions/dashboardTypes/DisplayTypes";
import { tGeneratedDashboardType, UserDashboard } from "../../abstractions/userDashboard/UserDashboard";
import { ActionTypes } from "../enums/ActionTypes";
import { Endpoints } from "../enums/Endpoints";
import { CallApiAction } from "../shared/middleware/api";

export interface AuditStatusesAction extends CallApiAction {
  type:
    | typeof ActionTypes.AuditStatusesRequest
    | typeof ActionTypes.AuditStatusesSuccess
    | typeof ActionTypes.AuditStatusesFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface DashboardTypesAction extends CallApiAction {
  type:
    | typeof ActionTypes.DashboardTypesRequest
    | typeof ActionTypes.DashboardTypesSuccess
    | typeof ActionTypes.DashboardTypesFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface DashboardTypesAction extends CallApiAction {
  type:
    | typeof ActionTypes.DashboardTypesRequest
    | typeof ActionTypes.DashboardTypesSuccess
    | typeof ActionTypes.DashboardTypesFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
}

export interface UserDashboardsAction extends CallApiAction {
  type:
    | typeof ActionTypes.UserDashboardsRequest
    | typeof ActionTypes.UserDashboardsSuccess
    | typeof ActionTypes.UserDashboardsFailure
    | typeof ActionTypes.UserDashboardsCreateRequest
    | typeof ActionTypes.UserDashboardsCreateSuccess
    | typeof ActionTypes.UserDashboardsCreateFailure
    | typeof ActionTypes.UserDashboardsUpdateRequest
    | typeof ActionTypes.UserDashboardsUpdateSuccess
    | typeof ActionTypes.UserDashboardsUpdateFailure
    | typeof ActionTypes.AllUserDashboardsUpdateRequest
    | typeof ActionTypes.AllUserDashboardsUpdateSuccess
    | typeof ActionTypes.AllUserDashboardsUpdateFailure
    | typeof ActionTypes.UserDashboardsDeleteRequest
    | typeof ActionTypes.UserDashboardsDeleteSuccess
    | typeof ActionTypes.UserDashboardsDeleteFailure
    | typeof ActionTypes.UserDashboardsClearError
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?:
    | {
      [name: string]: unknown;
    }
    | UserDashboard[];
  payload?: {
    item?: Record<string, unknown> | UserDashboard;
    prevItem?: Record<string, unknown> | UserDashboard;
  };
  UserDashboardId?: string;
}

export interface UserDashboardCardAction extends CallApiAction {
  type:
    | typeof ActionTypes.UserDashboardCardRequest
    | typeof ActionTypes.UserDashboardCardSuccess
    | typeof ActionTypes.UserDashboardCardFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>[];
  UserDashboardId?: string;
}

export interface AllUserDashboardCardAction extends CallApiAction {
  type:
    | typeof ActionTypes.AllUserDashboardCardsRequest
    | typeof ActionTypes.AllUserDashboardCardsSuccess
    | typeof ActionTypes.AllUserDashboardCardsFailure
    | typeof ActionTypes.CallAPI
    | typeof ActionTypes.None;
  response?: Record<string, unknown>;
  UserDashboardId?: string;
}

// Fetches Audit Statuses from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAuditStatuses = (): AuditStatusesAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AuditStatusesRequest,
      ActionTypes.AuditStatusesSuccess,
      ActionTypes.AuditStatusesFailure,
    ],
    endpoint: Endpoints.AuditStatuses,
  },
  type: ActionTypes.CallAPI,
});

export const loadAuditStatuses = (): AppThunkAction<Action> => (dispatch) => {
  return dispatch(fetchAuditStatuses());
};

// Fetches Dashboard Types from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAllDashboardTypes = (): DashboardTypesAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.DashboardTypesRequest,
      ActionTypes.DashboardTypesSuccess,
      ActionTypes.DashboardTypesFailure,
    ],
    endpoint: Endpoints.DashboardTypes,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadAllDashboardTypes =
  (): AppThunkAction<Action> => (dispatch) => {
    return dispatch(fetchAllDashboardTypes());
  };

// Fetches User Dashboards from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchUserDashboards = (): UserDashboardsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardsRequest,
      ActionTypes.UserDashboardsSuccess,
      ActionTypes.UserDashboardsFailure,
    ],
    endpoint: Endpoints.UserDashboards,
    useMy: true,
  },
  [ActionTypes.CallODATA]: {
    query: `?$orderby=Ordinal asc,LastModificationTime desc&$count=true`,
  },
  type: ActionTypes.CallAPI,
});

export const loadUserDashboards = (): AppThunkAction<Action> => (dispatch) => {
  return dispatch(fetchUserDashboards());
};


export interface UserDashboardGeneratedScoringAction extends CallApiAction {
  type:
  | typeof ActionTypes.CallAPI
  | typeof ActionTypes.None;
  response?: tGeneratedDashboardType[];
}

const fetcUserDashboardGeneratedScoringData = (): UserDashboardGeneratedScoringAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardsRequest,
      ActionTypes.UserDashboardsSuccess,
      ActionTypes.UserDashboardsFailure,
    ],
    endpoint: Endpoints.UserDashboardGeneratedScoringData,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});


export const loadUserDashboardGeneratedScoringData = (): AppThunkAction<Action> => (dispatch) => {
  return dispatch(fetcUserDashboardGeneratedScoringData());
};



// Patch an existing User Dashboard
const patchUserDashboard = (
  payload: UserDashboard,
  prevItem: UserDashboard
): UserDashboardsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardsUpdateRequest,
      ActionTypes.UserDashboardsUpdateSuccess,
      ActionTypes.UserDashboardsUpdateFailure,
    ],
    endpoint: Endpoints.UserDashboards,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  [ActionTypes.CallODATA]: {
    query: `/${payload.Id as string}`,
  },
  payload: {
    item: payload,
    prevItem: prevItem,
  },
  type: ActionTypes.CallAPI,
});

export const updateUserDashboard =
  (payload: UserDashboard): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const { dashboards } = getState();
      let prevItem = {} as UserDashboard;
      if (dashboards && dashboards.dashboards) {
        prevItem = dashboards.dashboards[payload.Id as string];
      }
      delete payload.Width;
      delete payload.Height;
      delete payload.PositionX;
      delete payload.PositionY;
      delete payload.Static;
      delete payload.Type;
      delete payload.LastModificationTime;
      delete payload.CreationTime;
      delete payload.Data;
      if (!payload.Options) {
        if (prevItem && prevItem.Options) {
          payload.Options = prevItem.Options;
        } else {
          payload.Options = {};
        }
      }
      return dispatch(patchUserDashboard(payload, prevItem));
    };

// Patch all User Dashboards
const patchAllUserDashboards = (
  payload: Record<string, UserDashboard>,
  prevItem: Record<string, UserDashboard>
): UserDashboardsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AllUserDashboardsUpdateRequest,
      ActionTypes.AllUserDashboardsUpdateSuccess,
      ActionTypes.AllUserDashboardsUpdateFailure,
    ],
    endpoint: Endpoints.UpdateAllUserDashboards,
    useMy: true,
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  },
  payload: {
    item: payload,
    prevItem: prevItem,
  },
  type: ActionTypes.CallAPI,
});

export const updateAllUserDashboards =
  (payload: Record<string, UserDashboard>): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const { dashboards } = getState();
      const updatedPayload = {} as Record<string, UserDashboard>;
      forEach(payload, (dashboard) => {
        const returnDash = cloneDeep(dashboard);
        delete returnDash.Width;
        delete returnDash.Height;
        delete returnDash.PositionX;
        delete returnDash.PositionY;
        delete returnDash.Static;
        delete returnDash.Type;
        delete returnDash.LastModificationTime;
        delete returnDash.CreationTime;
        delete returnDash.Data;
        if (returnDash.Id) {
          updatedPayload[returnDash.Id] = returnDash;
        }
      });
      return dispatch(
        patchAllUserDashboards(
          updatedPayload,
          (dashboards && dashboards.dashboards
            ? dashboards.dashboards
            : {}) as Record<string, UserDashboard>
        )
      );
    };

// Add a new User Dashboard
const postUserDashboard = (
  userDashboard: Record<string, unknown>
): UserDashboardsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardsCreateRequest,
      ActionTypes.UserDashboardsCreateSuccess,
      ActionTypes.UserDashboardsCreateFailure,
    ],
    endpoint: Endpoints.UserDashboards,
    useMy: true,
    options: {
      method: "POST",
      body: JSON.stringify(userDashboard),
    },
  },
  [ActionTypes.CallODATA]: {
    query: "",
  },
  type: ActionTypes.CallAPI,
});

const getDashboardType = (dashboardType: string) => {
  switch (dashboardType) {
    case "SubmissionsPerNeed":
    case "AverageSubmissionRating":
      return DisplayTypes.Line;
    case "RequestStatusBreakdown":
    case "SubmissionStatusBreakdown":
    case "SubmissionRejectCodeBreakdown":
      return DisplayTypes.Doughnut;
    default:
      return DisplayTypes.Line;
  }
};

export const createUserDashboard =
  (userDashboard: UserDashboard): AppThunkAction<UserDashboardsAction> =>
    (dispatch, getState) => {
      const dash = userDashboard as unknown as Record<string, unknown>;
      delete dash.Id;
      delete dash.Type;
      dash["UserId"] = getState()?.account?.Id;
      dash["Display"] = getDashboardType(dash.ReportType as string);
      dash["Size"] = "Medium";
      dash["Ordinal"] = 1;
      if (!dash.Options) {
        dash.Options = {};
      }
      return dispatch(postUserDashboard(dash));
    };

// Fetches User Dashboard card from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchUserDashboardCard = (
  userDashboardId: string
): UserDashboardCardAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardCardRequest,
      ActionTypes.UserDashboardCardSuccess,
      ActionTypes.UserDashboardCardFailure,
    ],
    endpoint: `${Endpoints.UserDashboardCard}/${userDashboardId}`,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
  UserDashboardId: userDashboardId,
});

// Fetches All User Dashboard card reports from API
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAllUserDashboardCards = (): AllUserDashboardCardAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.AllUserDashboardCardsRequest,
      ActionTypes.AllUserDashboardCardsSuccess,
      ActionTypes.AllUserDashboardCardsFailure,
    ],
    endpoint: Endpoints.AllUserDashboardCards,
    useMy: true,
  },
  type: ActionTypes.CallAPI,
});

export const loadUserDashboardCard =
  (userDashboardId: string): AppThunkAction<UserDashboardCardAction> =>
    (dispatch) => {
      return dispatch(fetchUserDashboardCard(userDashboardId));
    };

// Loads all the user dashboard cards
export const loadAllUserDashboardCards =
  (): AppThunkAction<AllUserDashboardCardAction> => (dispatch) => {
    return dispatch(fetchAllUserDashboardCards());
  };

// Deletes a User Dashboard using API
// Relies on the custom API middleware defined in ../middleware/api.js.
const deleteUserDashboard = (
  userDashboard: UserDashboard
): UserDashboardsAction => ({
  [ActionTypes.CallAPI]: {
    types: [
      ActionTypes.UserDashboardsDeleteRequest,
      ActionTypes.UserDashboardsDeleteSuccess,
      ActionTypes.UserDashboardsDeleteFailure,
    ],
    endpoint: Endpoints.UserDashboards + `/${userDashboard.Id as string}`,
    useMy: true,
    options: {
      method: "DELETE",
    },
  },
  [ActionTypes.CallODATA]: {
    query: "",
  },
  type: ActionTypes.CallAPI,
  payload: {
    item: {
      Id: userDashboard.Id,
    },
    prevItem: userDashboard as unknown as Record<string, unknown>,
  },
});

export const removeUserDashboard =
  (userDashboardId: string): AppThunkAction<Action> =>
    (dispatch, getState) => {
      const dashboards = getState()?.dashboards?.dashboards;
      if (dashboards && dashboards[userDashboardId]) {
        return dispatch(deleteUserDashboard(dashboards[userDashboardId]));
      }
      return Promise.reject();
    };

// Clears the Dashboards errorMessage
const clearErrorMessage = () => ({
  type: ActionTypes.UserDashboardsClearError,
});

export const clearDashboardsError =
  (): AppThunkAction<Action> => (dispatch) => {
    return dispatch(clearErrorMessage());
  };

export interface DashboardsActionCreators {
  loadAuditStatuses: typeof loadAuditStatuses;
  loadAllDashboardTypes: typeof loadAllDashboardTypes;
  loadUserDashboards: typeof loadUserDashboards;
  loadUserDashboardGeneratedScoringData: typeof loadUserDashboardGeneratedScoringData;
  loadUserDashboardCard: typeof loadUserDashboardCard;
  loadAllUserDashboardCards: typeof loadAllUserDashboardCards;
  updateAllUserDashboards: typeof updateAllUserDashboards;
  updateUserDashboard: typeof updateUserDashboard;
  createUserDashboard: typeof createUserDashboard;
  removeUserDashboard: typeof removeUserDashboard;
  clearDashboardsError: typeof clearDashboardsError;
}
