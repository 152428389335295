// React/Redux Library Imports
import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, RouteComponentProps } from "react-router-dom";

// Redux-Store Imports
import { ApplicationState, DispatchThunkAction } from "../../../state/";
import {
  HasModule,
  hasModuleSelector,
} from "../../../state/appsettings/selectors";

// Material-ui Imports
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Badge,
  CssBaseline,
  Drawer,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Tabs,
  Tab,
  Toolbar,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
  Snackbar,
} from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import DashboardIcon from "@mui/icons-material/Dashboard";

// Components
import Restricted from "../../shared/Restricted";
import NotificationPopover from "../../shared/NotificationPopover";
import NexusAvatar from "../../shared/NexusAvatar";

// Other Imports
import { Policies } from "../../../types";
import NexusAiLogo from "../../../assets/images/xs-white.png";
import { User } from "../../../abstractions/user/User";
import { forEach } from "lodash";
import { Location } from "history";
import { logoutUser } from "state/authentication/actions";
import { loadUserNotifications } from "state/notifications/actions";
import { ActionTypes } from "state/enums/ActionTypes";
import Alert from "@mui/material/Alert";
const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& a": {
        color: "inherit",
      },
      minHeight: "100%",
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer - 2,
      background:
        "linear-gradient(90deg, rgba(43,7,109,1) 30%, rgba(73,19,172,1) 62%, rgba(52,10,128,1) 88%)",
      height: 70,
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `none`,
        backgroundColor: `${theme.palette.primary.dark}`,
      },
    },
    toolBar: {
      padding: `0 25px`,
      boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
      zIndex: 2,
    },
    toolBarShift: {
      paddingLeft: 73,
    },
    appBarImg: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "400px",
      backgroundPosition: "left",
    },
    menuButton: {
      marginRight: 11,
      color: theme.palette.info.main,
      "& svg": {
        height: 25,
        width: 25,
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerOpen: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
    logo: {
      marginTop: 22,
      marginBottom: 21,
      width: 150,
      [theme.breakpoints.up("md")]: {
        width: "unset",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      height: 70,
    },
    tabs: {
      margin: `0 0 0 170px`,
    },
    tabsIndicator: {
      backgroundColor: theme.palette.info.main,
    },
    moduleTab: {
      height: 68,
      width: 80,
      minWidth: 80,
      minHeight: 68,
      margin: `0 10px 0 0`,
      padding: `15px 5px 14px`,
      fontSize: 13,
      color: theme.palette.toolbarTabs.text,
      "&:hover": {
        textDecoration: "none",
      },
      "& svg": {
        width: 25,
        height: 25,
        color: theme.palette.toolbarTabs.icon,
      },
    },
    moduleTabSelected: {
      color: theme.palette.toolbarTabs.selectedText,
      "& svg": {
        color: theme.palette.toolbarTabs.selectedIcon,
      },
    },
    content: {
      flexGrow: 1,
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      marginLeft: -drawerWidth,
      background: theme.backgroundStyles.linearGradient,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    userIcon: {
      margin: `0 0 0 10px`,
      "& svg": {
        height: 25,
        width: 25,
      },
    },
    spacer: {
      height: 70,
    },
    hidden: {
      display: "none",
    },
  });

interface DispatchProps {
  logoutUser: DispatchThunkAction<typeof logoutUser>;
  loadUserNotifications: DispatchThunkAction<typeof loadUserNotifications>;
}
interface StateProps {
  notificationCount?: number;
  hasModule: HasModule;
  user: User;
}

type ModuleString =
  | "briefs"
  | "ideas"
  | "insights"
  | "talent"
  | "dashboard"
  | "landing"
  | "home";

interface RouteProps {
  tab: ModuleString;
}

// type MiniDrawerProps = StateProps &
// DispatchProps &
// WithStyles<typeof styles> &
// WithTheme &
// RouteComponentProps<RouteProps>;
interface MiniDrawerProps
  extends StateProps,
  DispatchProps,
  WithStyles<typeof styles>,
  WithTheme,
  RouteComponentProps<RouteProps> {
  showDashboard: number; // Add this prop
}
interface MiniDrawerState {
  anchorEl: HTMLElement | null;
  open: boolean;
  mobileMoreAnchorEl: HTMLElement | null;
  notePopoverOpen: boolean;
  notePopoverAnchorEl: HTMLElement | null;
  moduleTab: ModuleString;
  error: string | null;
}

class MiniDrawer extends React.Component<MiniDrawerProps, MiniDrawerState> {
  constructor(props: MiniDrawerProps) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      notePopoverOpen: false,
      notePopoverAnchorEl: null,
      moduleTab: "home",
      error: null,
    };
  }

  componentDidMount() {
    this.changeTabsDynamically();
  }

  changeTabsDynamically = () => {
    const { location } = this.props;

    if (location && location.pathname) {
      const paths: ModuleString[] = ["insights", "home", "dashboard"];
      forEach(paths, (pathStr) => {
        if (location.pathname.includes(pathStr)) {
          this.changeTab(undefined, pathStr);
        }
      });
    }
  };

  toggleNotePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const { notePopoverOpen } = this.state;
    void this.props.loadUserNotifications();
    this.setState({
      notePopoverAnchorEl: event?.currentTarget,
      notePopoverOpen: !notePopoverOpen,
    });
  };

  closeNotePopover = () => {
    this.setState({ notePopoverOpen: false });
  };

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  changeTab = (event?: React.ChangeEvent<unknown>, value?: ModuleString) => {
    if (value) {
      this.setState({ moduleTab: value });
    }
  };

  handleSignOut = () => {
    void this.props.logoutUser().then((res) => {
      const resp = res as { type: string; error: string };
      if (resp && resp.type == ActionTypes.LogoutSuccess) {
        localStorage.setItem("isManuallyLoggedOut", "true");
        window.location.assign(`${process.env.PUBLIC_URL}/logout`);
      } else {
        this.setState({ error: resp.error });
      }
    });
  };

  componentDidUpdate(prevProps: MiniDrawerProps) {
    if (this.props.location !== prevProps.location) {
      this.changeTabsDynamically();
    }
  }

  render() {
    const { classes, theme, notificationCount, children, user, showDashboard } =
      this.props;
    const {
      open,
      anchorEl,
      mobileMoreAnchorEl,
      notePopoverAnchorEl,
      notePopoverOpen,
      moduleTab,
      error,
    } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const menuId = "primary-account-menu";
    
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem
          onClick={this.handleMenuClose}
          component={Link}
          to={`${process.env.PUBLIC_URL}/account`}
        >
          Account
        </MenuItem>
        <Restricted
          requiredPolicies={[
            Policies.UserAdministrators,
            Policies.GroupAdministrators,
            Policies.TeamAdministrators,
          ]}
          policyComparison="OR"
        >
          <MenuItem
            onClick={this.handleMenuClose}
            component={Link}
            to={`${process.env.PUBLIC_URL}/admin`}
          >
            Administration
          </MenuItem>
        </Restricted>
        <MenuItem
          id="menu-sign-out"
          onClick={this.handleSignOut}
          component={"a"}
        >
          Sign Out
        </MenuItem>
      </Menu>
    );

    const mobileMenuId = "primary-account-menu-mobile";
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
        onClick={this.handleMobileMenuClose}
      >
        {showDashboard === 0 && (
          <MenuItem component={Link} to={`${process.env.PUBLIC_URL}/insights`}>
            Projects
          </MenuItem>
        )}
        <MenuItem
          component={Link}
          to={`${process.env.PUBLIC_URL}/notifications`}
        >
          Notification
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>Profile</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar
            className={clsx(classes.appBarImg, classes.toolBar, {
              [classes.toolBarShift]: open,
            })}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
                [classes.hidden]: true,
              })}
              id="main-menu-icon-button"
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.logo} src={NexusAiLogo} />
            <Hidden xsDown implementation="css">
              {showDashboard !== undefined &&
                (showDashboard === 0 ? (
                  <Tabs
                    onChange={this.changeTab}
                    value={moduleTab}
                    className={classes.tabs}
                    classes={{ indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      className={classes.moduleTab}
                      classes={{ selected: classes.moduleTabSelected }}
                      label="Home"
                      value="home"
                      component={Link}
                      to={`${process.env.PUBLIC_URL}/home`}
                      id="home"
                    ></Tab>
                    <Tab
                      className={classes.moduleTab}
                      classes={{ selected: classes.moduleTabSelected }}
                      label="Projects"
                      value="insights"
                      component={Link}
                      to={`${process.env.PUBLIC_URL}/insights`}
                    ></Tab>
                    {/* Uncomment this if needed */}
                    {/* <Tab
          className={classes.moduleTab}
          classes={{ selected: classes.moduleTabSelected }}
          label="Dashboard"
          value="dashboard"
          component={Link}
          to={`${process.env.PUBLIC_URL}/dashboard`}
        ></Tab> */}
                  </Tabs>
                ) : (
                  <Tabs
                    onChange={this.changeTab}
                    value={moduleTab}
                    className={classes.tabs}
                    classes={{ indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      className={classes.moduleTab}
                      classes={{ selected: classes.moduleTabSelected }}
                      label="Dashboard"
                      value="dashboard"
                      component={Link}
                      to={`${process.env.PUBLIC_URL}/dashboard`}
                    ></Tab>
                  </Tabs>
                ))}
            </Hidden>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label={`show ${
                  notificationCount as number
                } new notifications`}
                color="inherit"
                onClick={this.toggleNotePopoverOpen}
                className={classes.userIcon}
                id="notification-icon-button"
              >
                <Badge
                  badgeContent={notificationCount}
                  color="secondary"
                  overlap="rectangular"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <NotificationPopover
                open={notePopoverOpen}
                anchorEl={notePopoverAnchorEl}
                onClose={this.closeNotePopover}
              />
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                className={classes.userIcon}
                id="account-menu-icon-button"
              >
                <NexusAvatar user={user} />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                id="mobile-menu-icon-button"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          {renderMobileMenu}
          {renderMenu}
        </AppBar>
        <Drawer
          variant="persistent"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
          })}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose} id="close-main-menu">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {[
              {
                text: "Dashboard",
                url: `${process.env.PUBLIC_URL}/dashboard`,
                icon: <DashboardIcon />,
              },
            ].map((link) => (
              <ListItem component={Link} to={link.url} key={link.text} button>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.spacer} />
          {children}
        </main>
        <Snackbar
          id="snackbar-signout-error"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!error}
          autoHideDuration={6000}
          onClose={() => this.setState({ error: null })}
        >
          <Alert
            onClose={() => this.setState({ error: null })}
            severity="error"
          >
            {error}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  notificationCount: state?.notifications?.count?.ALL?.Count,
  hasModule: hasModuleSelector(state),
  user: state.account,
});

const mapDispatchToProps = {
  logoutUser,
  loadUserNotifications,
};
export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(MiniDrawer) as React.ComponentType<{
  location: Location;
  showDashboard: number;
}>;
