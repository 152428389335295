export enum Endpoints {
  None = "None",
  Errors = "/Errors",
  Account = "/Account",
  AccountFeedback = "/Account/Feedback",
  AccountInvitationCallback = "/Account/InvitationCallback",
  AllUserDashboardCards = "/Reports",
  AppSettings = "/AppSettings",
  AuditStatuses = "/Enums/AuditStatus",
  Batch = "/$batch",
  ChangePassword = "/Password",
  ChangeEmail = "/Email",
  CreateUser = "/Account/Register",
  DashboardTypes = "/Dashboards/Types",
  Documents = "/Documents",
  DocumentsUploadChunk = "/Documents/Upload/Chunk",
  DocumentsUploadChunkComplete = "/Documents/Upload/Chunk/Complete",
  Feedback = "/Feedback",
  Groups = "/Groups",
  GroupRoles = "/GroupRoles",
  Insights = "/Insights",
  Localization = "/StringResources",
  Login = "/Account/Login",
  Logout = "/Account/Logout",
  PasswordLessLogin = "/Account/Login1Fa",
  NotificationCountByType = "/Notifications/Count",
  Notifications = "/Notifications",
  PasswordResetStep1 = "/Account/PasswordResetStep1",
  PasswordResetStep2 = "/Account/PasswordResetStep2",
  Policies = "/Policies",
  ProfileTypes = "/Enums/UserProfileTypes",
  Refresh = "/Account/Refresh",
  Requests = "/Requests",
  Roles = "/Roles",
  Success = "/Account/Success",
  UpdateAllUserDashboards = "/Dashboards",
  UserDashboardCard = "/Dashboards",
  UserDashboards = "/Dashboards",
  UserDashboardGeneratedScoringData = "/Dashboards/GetAlgorithmDataForDashboard",
  UserGroups = "/UserGroups",
  UserTeams = "/UserTeams",
  Users = "/Users",
  UsersByTeam = "/Team",
  Teams = "/Teams",
  TwoFactor = "/TwoFactor",
  TwoFactorEnable = "/Account/TwoFactorEnable",
  TwoFactorByEmail = "/Account/TwoFactorByEmail",
  TwoFactorByApp = "/Account/TwoFactorByApp",
  TwoFactorRecovery = "/Account/LoginRecovery",
  Login2Fa = "/Account/Login2Fa",
  DeleteRememberMe = "/Account/Delete2FARemembermeCookie",
  IsEnterpriseClient = "/IsEnterpriseClient" 
}
