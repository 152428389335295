import * as React from "react";
import { compose } from "redux";
import { withCookies, ReactCookieProps } from "react-cookie";
import actions from "./state/actions";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  createHub,
  HubOptions,
  HubListener,
  unsubscribeToChanges,
} from "./helpers/signalRUtils";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AuthenticationState } from "./state/authentication";
import { AccountState } from "./state/account";
import { AppSettingsState } from "./state/appsettings";
import { ApplicationState, DispatchThunkAction } from "./state";
import { AuthStatus } from "./types";
import Pages from "./routes/Pages";
import { createAppInsights } from "./AppInsights";
import "./styles/global.css";
import { ConfigKeys } from "state/enums/ConfigKeys";
import { Request } from "../src/abstractions/request/Request";
import { RequestStatus } from "abstractions/request/RequestStatus";
import { callRefreshToken } from "state/shared/middleware/api";
import { logoutUser } from "state/authentication/actions";
import { ActionTypes } from "state/enums/ActionTypes";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { googleAnalytics } from "helpers/googleAnalyticsUtils";
import { guidAreEqual } from "helpers/general";
import { InsightRequestAction } from "state/insightRequest/actions";

// import { UserNotification } from "abstractions/userNotification/UserNotification";
// import { InsightRequestAction } from "state/insightRequest/actions";

interface DispatchProps {
  getAuthenticationStatus: DispatchThunkAction<typeof getAuthenticationStatus>;
  loadAppSettings: DispatchThunkAction<typeof loadAppSettings>;
  loadAccount: DispatchThunkAction<typeof loadAccount>;
  loadUser: DispatchThunkAction<typeof loadUser>;
  loadLocalization: DispatchThunkAction<typeof loadLocalization>;
  loadNotificationCount: DispatchThunkAction<typeof loadNotificationCount>;
  loadAppSettingsCacheSuccess: DispatchThunkAction<
    typeof loadAppSettingsCacheSuccess
  >;
  loadCachedLocalizationSuccess: DispatchThunkAction<
    typeof loadCachedLocalizationSuccess
  >;
  updateRequestsStatus: DispatchThunkAction<typeof updateRequestsStatus>;
  logoutUser: DispatchThunkAction<typeof logoutUser>;
  loadInsights: DispatchThunkAction<typeof loadInsights>;
  loadInsightRequest: DispatchThunkAction<typeof loadInsightRequest>;
  loadEnterpriseClient: DispatchThunkAction<typeof loadEnterpriseClient>;
}

interface StateProps {
  authentication: AuthenticationState | undefined;
  account: AccountState | undefined;
  appsettings: AppSettingsState | undefined;
  tokenTimeout: number;
  timeOut: Date;
  selectedRequestId?: string;
  isEnterPriseClient: boolean;
  showDashboard: number;
}

type AppProps = StateProps & DispatchProps & ReactCookieProps;

type AppState = {
  appInsights: ApplicationInsights | null;
  refreshToken: boolean;
  refreshRequired: boolean;
  firstTokenRefresh: boolean;
  error: string | null;
  timeOut: Date;
  prevPathname: string;
};

const {
  getAuthenticationStatus,
  loadAppSettings,
  loadAccount,
  loadUser,
  loadLocalization,
  loadNotificationCount,
  loadAppSettingsCacheSuccess,
  loadCachedLocalizationSuccess,
  updateRequestsStatus,
  loadInsights,
  loadInsightRequest,
  loadEnterpriseClient
} = actions;

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      appInsights: null,
      refreshToken: true,
      refreshRequired: true,
      firstTokenRefresh: true,
      error: null,
      timeOut: new Date(),
      prevPathname: window.location.pathname,
    };
  }

  onIdle = () => {
    this.setState({
      ...this.state,
      refreshRequired: false,
      refreshToken: false,
    });
    if (
      this.props.authentication?.authenticationStatus === AuthStatus.Authorized
    ) {
      void this.props.logoutUser().then((res) => {
        const resp = res as { type: string; error: string };
        resp && resp.type == ActionTypes.LogoutSuccess
          ? window.location.assign(`${process.env.PUBLIC_URL}/logout`)
          : this.setState({ error: resp.error });
      });
    }
  };

  componentDidMount() {
    const url = new URL(window.location.href);
    const returnUrlMatch = url.searchParams.get("ReturnUrl");

    let requestId = "";

    if (returnUrlMatch) {
      const match = returnUrlMatch.match(/\/RequestCompleted\/(\w+)$/);
      if (match) {
        requestId = match[1];
      }
    } else {
      const notificationType = url.searchParams.get("notificationType");
      if (notificationType === "RequestCompleted") {
        requestId = url.pathname.split("/").pop() || "";
      }
    }
    if (requestId) {
      sessionStorage.setItem(ConfigKeys.PresentationId, requestId);
    }
    const cachedAppSettings = sessionStorage.getItem(
      ConfigKeys.AppSettingCache
    );
    const cachedLocalization = sessionStorage.getItem(
      ConfigKeys.LocalizationCache
    );
    if (!cachedAppSettings || !cachedLocalization) {
      void Promise.all([
        this.props.loadAppSettings(),
        this.props.loadLocalization(),
      ]).then(([appSettingResponse, localizationResponse]) => {
        sessionStorage.setItem(
          ConfigKeys.AppSettingCache,
          JSON.stringify(appSettingResponse)
        );
        sessionStorage.setItem(
          ConfigKeys.LocalizationCache,
          JSON.stringify(localizationResponse)
        );

        void this.props.getAuthenticationStatus();
      });
    } else {
      const parsedAppSettings = JSON.parse(cachedAppSettings);
      const parsedLocalization = JSON.parse(cachedLocalization);

      void this.props.loadAppSettingsCacheSuccess(parsedAppSettings.response);
      void this.props.loadCachedLocalizationSuccess(
        parsedLocalization.response
      );
      void this.props.getAuthenticationStatus();
    }
    googleAnalytics.trackPageView(window.location.pathname, "App.tsx");
    if (this.state.appInsights) this.state.appInsights.trackPageView();

    const timeInterval = setInterval(() => {
      const timeoutFromAPI = Math.floor(
        Number(this.props?.appsettings?.TokenTimeout) - (2 * 60000) / 60000
      );
      const startTime = this.state.timeOut;
      const endTime = new Date();
      // This will give difference in milliseconds
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);

      if (resultInMinutes >= timeoutFromAPI) {
        clearInterval(timeInterval);
        // if the user is ideal then we don't call refresh token api
        this.onIdle();
      }
    }, 60000);
  }
  refreshToken = () => {
    const timeout = Number(this.props?.appsettings?.TokenTimeout);
    const timeInterval = timeout - 1;

    this.setState({ ...this.state, refreshToken: false });
    setTimeout(() => {
      (async () => {
        this.state.refreshRequired && (await callRefreshToken());
      })()
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      this.setState({ ...this.state, refreshToken: true });
    }, timeInterval * 60000);
  };

  componentDidUpdate(prevProps: AppProps) {
    const { authentication } = prevProps;

    if (
      this.props.authentication?.authenticationStatus !==
        authentication?.authenticationStatus &&
      this.props.authentication?.authenticationStatus === AuthStatus.Authorized
    ) {
      void this.props.loadEnterpriseClient();
      this.createSignalRHub();
      void this.props.loadAccount();
      void this.props.loadNotificationCount();
            
      //setTimeout(() => {
      //  this.createSignalRHub();
      //}, 30000);
    }

    if (
      this.props.appsettings?.AppInsightsInstrumentKey &&
      !this.state.appInsights
    ) {
      this.setState({
        appInsights: createAppInsights(
          this.props.appsettings.AppInsightsInstrumentKey
        ),
      });
    }
    if (
      !prevProps.appsettings?.GoogleAnalyticsTagId &&
      this.props.appsettings?.GoogleAnalyticsTagId
    ) {
      googleAnalytics.initialize(this.props.appsettings?.GoogleAnalyticsTagId);
    }

    if (window.location.pathname !== this.state.prevPathname) {
      googleAnalytics.trackPageView(window.location.pathname, "App.tsx");
      this.state.appInsights?.trackPageView();
      this.setState({ prevPathname: window.location.pathname });
    }
    this.state.refreshToken && this.refreshToken();
  }

  componentWillUnmount() {
    this.state.appInsights?.flush(false) as void;
  }

  createSignalRHub = () => {
    const { appsettings, cookies } = this.props;
    if (cookies && appsettings && appsettings.signalRurl) {
      createHub({
        hubUrl: appsettings.signalRurl + "/signalr",
        authCookie: cookies.get(appsettings.TokenName) as string,
        onChange: this.signalROnChange as HubListener,
        onNotify: this.signalROnNotify as HubListener,
      } as HubOptions);
    }
  };

  signalROnNotify = (): void => {
    // const { updateRequestsStatus, loadInsightRequest } = this.props;
    void this.props.loadNotificationCount();
    // const notifications = n as UserNotification;
    // if (
    //   notifications &&
    //   notifications[0].NotificationType === "RequestCompleted"
    // ) {
    //   const requestId = notifications[0].Action.Id;
    //   unsubscribeToChanges(requestId as string);
    //   void loadInsightRequest(requestId).then((res: InsightRequestAction) => {
    //     void updateRequestsStatus(res.response as Request);
    //   });
    // }    
  };

  signalROnChange = (n: unknown): void => {
    const {
      updateRequestsStatus,
      loadInsights,
      selectedRequestId,
      loadInsightRequest,
    } = this.props;
    const changeRequest = (n as { Entity: Request }).Entity;
    void updateRequestsStatus(changeRequest);
    
    if (changeRequest.Status === RequestStatus.Completed) {
      unsubscribeToChanges(changeRequest.Id);
      if (
        changeRequest.Id &&
        selectedRequestId &&
        guidAreEqual(changeRequest.Id, selectedRequestId)
      ) {
        void loadInsightRequest(selectedRequestId);
        void loadInsights(changeRequest.Id, "Dias");
      }
    }
  };

  onActive = () => {
    this.setState({
      ...this.state,
      refreshRequired: true,
      timeOut: new Date(),
    });
  };

  render() {
    const { appsettings , showDashboard} = this.props;
    
    return (
      <React.Fragment>
        <div
          id="clickableContainer"
          onClick={() => {
            this.onActive();
          }}
        >
          <Helmet>
            {appsettings?.ApplicationTitle && (
              <title>{appsettings?.ApplicationTitle}</title>
            )}
            {!appsettings?.ApplicationTitle && <title></title>}
          </Helmet>
          <Pages showDashboard = {showDashboard}/>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!this.state.error}
          autoHideDuration={6000}
          onClose={() => this.setState({ error: null })}
        >
          <Alert
            onClose={() => this.setState({ error: null })}
            severity="error"
          >
            {this.state.error}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  account: state?.account,
  authentication: state?.authentication,
  appsettings: state?.appsettings,
  selectedRequestId: state?.insightRequest?.selectedRequestId,
  isEnterPriseClient: state?.insightRequest?.isEnterPriseClient,
  showDashboard: state?.insightRequest?.showDashboard,
});

const mapDispatchToProps = {
  logoutUser,
  getAuthenticationStatus,
  loadAppSettings,
  loadAccount,
  loadUser,
  loadLocalization,
  loadNotificationCount,
  loadAppSettingsCacheSuccess,
  loadCachedLocalizationSuccess,
  updateRequestsStatus,
  loadInsights,
  loadInsightRequest,
  loadEnterpriseClient,
};

export default compose(
  withCookies,
  connect(mapStateToProps, mapDispatchToProps)
)(App) as React.ComponentType;
